
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgba(40  ,44,52,0.75);
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circle {
  display: flex;
  width: 65px;
  height: 66px;
  background-color: green;
  border-radius: 50%;
  text-align: center;
}
.circle-rainbow {

  width: 75px;
  height: 75px;
  border-radius: 50%;

  background: linear-gradient(124deg, white, #282c34, grey, white, #282c34);
  background-size: 1800% 1800%;

  -webkit-animation: rainbow 5s ease infinite;
  -z-animation: rainbow 5s ease infinite;
  -o-animation: rainbow 5s ease infinite;
  animation: rainbow 5s ease infinite;}

.text {
  margin: auto;
}
.wrapper {
  height: 100vh;
  width: 100%;
  /*left:0;*/
  /*right: 0;*/
  /*top: 0;*/
  /*bottom: 0;*/
  padding: 30px !important;
  position: absolute;
  background: linear-gradient(124deg,
 #ff2400, #ff2400, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3
  );
  background-size: 1800% 1800%;

  -webkit-animation: rainbow 10s ease infinite;
  -z-animation: rainbow 10s ease infinite;
  -o-animation: rainbow 10s ease infinite;
  animation: rainbow 10s ease infinite;}

@-webkit-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}

.bangers {
  font-family: 'Bangers', cursive;
}

.MuiInputLabel-root {
  color: white !important;
}

.code {

  color: white !important;
}

.whiteButton {

}
html, body {
  margin:0px;
  height:100%;
}

.box {
  background:red;
  height:100%;
}
